const DEFAULT_CURRENCY = 'USD'
const CURRENCIES: Array<{ tz: string; value: string }> = []

import { GraphQLErrors } from '@apollo/client/errors'
import { decodeJwtPayload } from '@upvio/jwt-decode'
import { GraphQLErrorExtensions } from 'graphql'
import { NextRouter } from 'next/router'

import { AuthPayload } from '../types/graphql'

import { trackSigninEvent } from './tracker'

// Prepare variables for the authenticateUser request
export const authenticateUserVariables = (auth0Code: string) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const currency = CURRENCIES.find((c) => c.tz === timezone)
  const signupKey = localStorage.getItem('signupKey')
  const abTestVariant = localStorage.getItem('abTestVariant')

  return {
    auth0Code,
    timezone,
    currency: currency ? currency.value : DEFAULT_CURRENCY,
    signupKey,
    abTestVariant,
  }
}

interface OnSuccessfulSigninOptions {
  authPayload: AuthPayload
  router: NextRouter
  redirectUrl?: string
}

// Signin and set the environment
export const onSuccessfulSignin = ({
  authPayload,
  router,
  redirectUrl,
}: OnSuccessfulSigninOptions) => {
  // Fire Analytics event
  trackSigninEvent(authPayload)

  if (authPayload.redirectUrl) {
    window.location.href = authPayload.redirectUrl
    return
  }

  if (redirectUrl) {
    window.location.href = redirectUrl
    return
  }

  // Route to the home page
  router.push('/')
}

interface ErrorExtensions extends GraphQLErrorExtensions {
  exception?: { name: string }
}

export const isUnauthorizedError = (errors?: GraphQLErrors) => {
  if (!errors || errors.length == 0) {
    return false
  }

  return errors.some((graphqlError) => {
    const { exception } = graphqlError.extensions as ErrorExtensions
    return exception?.name === 'AuthError'
  })
}

export const accessTokenIsExpired = (accessToken: string) => {
  const decoded = decodeJwtPayload<{ expiresAt: number }>(accessToken)
  const now = Date.now()
  return decoded.expiresAt < now
}

interface AuthCallbackState {
  redirectUrl?: string
}
export const getAuthCallbackState = (
  stateId?: string,
): AuthCallbackState | false => {
  if (!stateId) {
    return false
  }

  const stateString = sessionStorage.getItem(stateId)
  if (!stateString) {
    return false
  }

  const stateData = JSON.parse(stateString)
  sessionStorage.removeItem(stateId)

  return stateData
}
